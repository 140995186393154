import React from "react"
import { Link } from "gatsby"
import { SectionTitle, SectionTitle2 } from "../../components/styled"
import WrapperRight from "../../components/WrapperRight"
import JobContent from "../../components/JobContent"
import SearchJob from "../../components/SearchJob"
import { FaAngleRight } from "react-icons/fa"
import scrollTo from "gatsby-plugin-smoothscroll"
import useBestJobs from "../../hooks/useBestJobs"
import { Seo } from "../../components/seo"
import Contact from "../../components/Contact"
import useJobs from "../../hooks/useJobs"
const JobsListing = () => {
  const dataJobs = useJobs("English")
  const dataBestJobs = useBestJobs()
  return (
    <>
      <Seo
        title={`メキシコ求人・就職・転職情報 | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
        image={
          dataJobs.length !== 0 ? dataJobs[0].types[0].image.publicURL : ""
        }
        description={`メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。`}
        author={`919mexico.com`}
        url={`https://www.919mexico.com/jobs-listing/`}
      />

      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__search--box">
                <SearchJob />
              </div>
            </div>
            <div className="jobs-listing__recommended-jobs">
              <div className="jobs-listing__recommended-jobs--title-link">
                <SectionTitle2 style={{ margin: "0" }}>
                  今週のオススメ求人
                </SectionTitle2>
                <Link to="/category/オススメのメキシコ求人">
                  <FaAngleRight />
                  これまでのオススメ求人を見る
                </Link>
              </div>
              <div className="jobs-listing__recommended-jobs--top-5">
                <p>{dataBestJobs[0].title}</p>
                <ul>
                  {dataBestJobs[0].topjobs.map((job, i) => (
                    <li>
                      <Link
                        to={`/osusume${job.Link}#matome0${i + 1}`}
                        onClick={() => scrollTo(`#matome0${i + 1}`)}
                        rel="noreferrer"
                      >
                        {job.Title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="jobs-listing__job-info">
              <SectionTitle2>求人情報</SectionTitle2>
              <hr className="u-line-bottom" />
              {dataJobs.map(jobs => (
                <>
                  <JobContent jobs={jobs} />
                </>
              ))}
              <Contact />
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobsListing
